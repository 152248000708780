import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import AmicaGuesthouse from './components/AmicaGuesthouse';
import AmicaRose from './components/AmicaRose/Overview';
import Gallery from './components/AmicaRose/Gallery';
import Map from './components/AmicaRose/Map';
import Contacts from './components/AmicaRose/Contacts';

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/amicaguesthouse" element={<AmicaGuesthouse />} />
      <Route path="/amicarose/overview" element={<AmicaRose />} />
      <Route path="/amicarose/gallery" element={<Gallery />} />
      <Route path="/amicarose/map" element={<Map />} />
      <Route path="/amicarose/contacts" element={<Contacts />} />
      <Route path="/amicarose/" element={<AmicaRose />} />
      {/* <Route path="/amicarose/booknow" element={<BookNow />} /> */}
    </Routes>
  </Router>
);

export default App;
