const Images = {
  bathroom: {
    img_3405: { thumbnail: require('./bathroom/img_3405_thumbnail.jpg'), full: require('./bathroom/img_3405.jpg') },
    img_3406: { thumbnail: require('./bathroom/img_3406_thumbnail.jpg'), full: require('./bathroom/img_3406.jpg') },
    img_3407: { thumbnail: require('./bathroom/img_3407_thumbnail.jpg'), full: require('./bathroom/img_3407.jpg') },
    img_3430: { thumbnail: require('./bathroom/img_3430_thumbnail.jpg'), full: require('./bathroom/img_3430.jpg') },
    img_3431: { thumbnail: require('./bathroom/img_3431_thumbnail.jpg'), full: require('./bathroom/img_3431.jpg') },
    img_3432: { thumbnail: require('./bathroom/img_3432_thumbnail.jpg'), full: require('./bathroom/img_3432.jpg') },
    img_3449: { thumbnail: require('./bathroom/img_3449_thumbnail.jpg'), full: require('./bathroom/img_3449.jpg') },
    img_3450: { thumbnail: require('./bathroom/img_3450_thumbnail.jpg'), full: require('./bathroom/img_3450.jpg') },
    img_3451: { thumbnail: require('./bathroom/img_3451_thumbnail.jpg'), full: require('./bathroom/img_3451.jpg') },
    img_3466: { thumbnail: require('./bathroom/img_3466_thumbnail.jpg'), full: require('./bathroom/img_3466.jpg') },
    img_3488: { thumbnail: require('./bathroom/img_3488_thumbnail.jpg'), full: require('./bathroom/img_3488.jpg') },
    img_3505: { thumbnail: require('./bathroom/img_3505_thumbnail.jpg'), full: require('./bathroom/img_3505.jpg') },
    img_3506: { thumbnail: require('./bathroom/img_3506_thumbnail.jpg'), full: require('./bathroom/img_3506.jpg') },
    img_3507: { thumbnail: require('./bathroom/img_3507_thumbnail.jpg'), full: require('./bathroom/img_3507.jpg') },
    img_3508: { thumbnail: require('./bathroom/img_3508_thumbnail.jpg'), full: require('./bathroom/img_3508.jpg') },
    img_3509: { thumbnail: require('./bathroom/img_3509_thumbnail.jpg'), full: require('./bathroom/img_3509.jpg') }
  },
  bedroom1: {
    img_3419: { thumbnail: require('./bedroom1/img_3419_thumbnail.jpg'), full: require('./bedroom1/img_3419.jpg') },
    img_3420: { thumbnail: require('./bedroom1/img_3420_thumbnail.jpg'), full: require('./bedroom1/img_3420.jpg') },
    img_3421: { thumbnail: require('./bedroom1/img_3421_thumbnail.jpg'), full: require('./bedroom1/img_3421.jpg') },
    img_3422: { thumbnail: require('./bedroom1/img_3422_thumbnail.jpg'), full: require('./bedroom1/img_3422.jpg') },
    img_3423: { thumbnail: require('./bedroom1/img_3423_thumbnail.jpg'), full: require('./bedroom1/img_3423.jpg') },
    img_3424: { thumbnail: require('./bedroom1/img_3424_thumbnail.jpg'), full: require('./bedroom1/img_3424.jpg') },
    img_3425: { thumbnail: require('./bedroom1/img_3425_thumbnail.jpg'), full: require('./bedroom1/img_3425.jpg') },
    img_3426: { thumbnail: require('./bedroom1/img_3426_thumbnail.jpg'), full: require('./bedroom1/img_3426.jpg') },
    img_3427: { thumbnail: require('./bedroom1/img_3427_thumbnail.jpg'), full: require('./bedroom1/img_3427.jpg') },
    img_3428: { thumbnail: require('./bedroom1/img_3428_thumbnail.jpg'), full: require('./bedroom1/img_3428.jpg') },
    img_3429: { thumbnail: require('./bedroom1/img_3429_thumbnail.jpg'), full: require('./bedroom1/img_3429.jpg') },
    img_3452: { thumbnail: require('./bedroom1/img_3452_thumbnail.jpg'), full: require('./bedroom1/img_3452.jpg') },
    img_3453: { thumbnail: require('./bedroom1/img_3453_thumbnail.jpg'), full: require('./bedroom1/img_3453.jpg') },
    img_3454: { thumbnail: require('./bedroom1/img_3454_thumbnail.jpg'), full: require('./bedroom1/img_3454.jpg') },
    img_3455: { thumbnail: require('./bedroom1/img_3455_thumbnail.jpg'), full: require('./bedroom1/img_3455.jpg') },
    img_3463: { thumbnail: require('./bedroom1/img_3463_thumbnail.jpg'), full: require('./bedroom1/img_3463.jpg') },
    img_3495: { thumbnail: require('./bedroom1/img_3495_thumbnail.jpg'), full: require('./bedroom1/img_3495.jpg') },
    img_3496: { thumbnail: require('./bedroom1/img_3496_thumbnail.jpg'), full: require('./bedroom1/img_3496.jpg') },
    img_3498: { thumbnail: require('./bedroom1/img_3498_thumbnail.jpg'), full: require('./bedroom1/img_3498.jpg') },
    img_3499: { thumbnail: require('./bedroom1/img_3499_thumbnail.jpg'), full: require('./bedroom1/img_3499.jpg') },
    img_3500: { thumbnail: require('./bedroom1/img_3500_thumbnail.jpg'), full: require('./bedroom1/img_3500.jpg') },
    img_3501: { thumbnail: require('./bedroom1/img_3501_thumbnail.jpg'), full: require('./bedroom1/img_3501.jpg') },
    img_3502: { thumbnail: require('./bedroom1/img_3502_thumbnail.jpg'), full: require('./bedroom1/img_3502.jpg') },
    img_3504: { thumbnail: require('./bedroom1/img_3504_thumbnail.jpg'), full: require('./bedroom1/img_3504.jpg') }
  },
  bedroom2: {
    img_3417: { thumbnail: require('./bedroom2/img_3417_thumbnail.jpg'), full: require('./bedroom2/img_3417.jpg') },
    img_3418: { thumbnail: require('./bedroom2/img_3418_thumbnail.jpg'), full: require('./bedroom2/img_3418.jpg') },
    img_3456: { thumbnail: require('./bedroom2/img_3456_thumbnail.jpg'), full: require('./bedroom2/img_3456.jpg') },
    img_3457: { thumbnail: require('./bedroom2/img_3457_thumbnail.jpg'), full: require('./bedroom2/img_3457.jpg') },
    img_3458: { thumbnail: require('./bedroom2/img_3458_thumbnail.jpg'), full: require('./bedroom2/img_3458.jpg') },
    img_3464: { thumbnail: require('./bedroom2/img_3464_thumbnail.jpg'), full: require('./bedroom2/img_3464.jpg') },
    img_3465: { thumbnail: require('./bedroom2/img_3465_thumbnail.jpg'), full: require('./bedroom2/img_3465.jpg') },
    img_3489: { thumbnail: require('./bedroom2/img_3489_thumbnail.jpg'), full: require('./bedroom2/img_3489.jpg') },
    img_3490: { thumbnail: require('./bedroom2/img_3490_thumbnail.jpg'), full: require('./bedroom2/img_3490.jpg') },
    img_3491: { thumbnail: require('./bedroom2/img_3491_thumbnail.jpg'), full: require('./bedroom2/img_3491.jpg') },
    img_3492: { thumbnail: require('./bedroom2/img_3492_thumbnail.jpg'), full: require('./bedroom2/img_3492.jpg') },
    img_3493: { thumbnail: require('./bedroom2/img_3493_thumbnail.jpg'), full: require('./bedroom2/img_3493.jpg') },
    img_3494: { thumbnail: require('./bedroom2/img_3494_thumbnail.jpg'), full: require('./bedroom2/img_3494.jpg') },
    img_3497: { thumbnail: require('./bedroom2/img_3497_thumbnail.jpg'), full: require('./bedroom2/img_3497.jpg') },
    img_3503: { thumbnail: require('./bedroom2/img_3503_thumbnail.jpg'), full: require('./bedroom2/img_3503.jpg') }
  },
  kitchen: {
    img_3410: { thumbnail: require('./kitchen/img_3410_thumbnail.jpg'), full: require('./kitchen/img_3410.jpg') },
    img_3414: { thumbnail: require('./kitchen/img_3414_thumbnail.jpg'), full: require('./kitchen/img_3414.jpg') },
    img_3415: { thumbnail: require('./kitchen/img_3415_thumbnail.jpg'), full: require('./kitchen/img_3415.jpg') },
    img_3416: { thumbnail: require('./kitchen/img_3416_thumbnail.jpg'), full: require('./kitchen/img_3416.jpg') },
    img_3439: { thumbnail: require('./kitchen/img_3439_thumbnail.jpg'), full: require('./kitchen/img_3439.jpg') },
    img_3440: { thumbnail: require('./kitchen/img_3440_thumbnail.jpg'), full: require('./kitchen/img_3440.jpg') },
    img_3459: { thumbnail: require('./kitchen/img_3459_thumbnail.jpg'), full: require('./kitchen/img_3459.jpg') },
    img_3460: { thumbnail: require('./kitchen/img_3460_thumbnail.jpg'), full: require('./kitchen/img_3460.jpg') },
    img_3461: { thumbnail: require('./kitchen/img_3461_thumbnail.jpg'), full: require('./kitchen/img_3461.jpg') },
    img_3462: { thumbnail: require('./kitchen/img_3462_thumbnail.jpg'), full: require('./kitchen/img_3462.jpg') },
    img_3478: { thumbnail: require('./kitchen/img_3478_thumbnail.jpg'), full: require('./kitchen/img_3478.jpg') },
    img_3479: { thumbnail: require('./kitchen/img_3479_thumbnail.jpg'), full: require('./kitchen/img_3479.jpg') },
    img_3480: { thumbnail: require('./kitchen/img_3480_thumbnail.jpg'), full: require('./kitchen/img_3480.jpg') },
    img_3481: { thumbnail: require('./kitchen/img_3481_thumbnail.jpg'), full: require('./kitchen/img_3481.jpg') }
  },
  livingroom: {
    img_3408: { thumbnail: require('./livingroom/img_3408_thumbnail.jpg'), full: require('./livingroom/img_3408.jpg') },
    img_3409: { thumbnail: require('./livingroom/img_3409_thumbnail.jpg'), full: require('./livingroom/img_3409.jpg') },
    img_3412: { thumbnail: require('./livingroom/img_3412_thumbnail.jpg'), full: require('./livingroom/img_3412.jpg') },
    img_3413: { thumbnail: require('./livingroom/img_3413_thumbnail.jpg'), full: require('./livingroom/img_3413.jpg') },
    img_3433: { thumbnail: require('./livingroom/img_3433_thumbnail.jpg'), full: require('./livingroom/img_3433.jpg') },
    img_3434: { thumbnail: require('./livingroom/img_3434_thumbnail.jpg'), full: require('./livingroom/img_3434.jpg') },
    img_3435: { thumbnail: require('./livingroom/img_3435_thumbnail.jpg'), full: require('./livingroom/img_3435.jpg') },
    img_3436: { thumbnail: require('./livingroom/img_3436_thumbnail.jpg'), full: require('./livingroom/img_3436.jpg') },
    img_3437: { thumbnail: require('./livingroom/img_3437_thumbnail.jpg'), full: require('./livingroom/img_3437.jpg') },
    img_3438: { thumbnail: require('./livingroom/img_3438_thumbnail.jpg'), full: require('./livingroom/img_3438.jpg') },
    img_3441: { thumbnail: require('./livingroom/img_3441_thumbnail.jpg'), full: require('./livingroom/img_3441.jpg') },
    img_3442: { thumbnail: require('./livingroom/img_3442_thumbnail.jpg'), full: require('./livingroom/img_3442.jpg') },
    img_3443: { thumbnail: require('./livingroom/img_3443_thumbnail.jpg'), full: require('./livingroom/img_3443.jpg') },
    img_3467: { thumbnail: require('./livingroom/img_3467_thumbnail.jpg'), full: require('./livingroom/img_3467.jpg') },
    img_3468: { thumbnail: require('./livingroom/img_3468_thumbnail.jpg'), full: require('./livingroom/img_3468.jpg') },
    img_3469: { thumbnail: require('./livingroom/img_3469_thumbnail.jpg'), full: require('./livingroom/img_3469.jpg') },
    img_3470: { thumbnail: require('./livingroom/img_3470_thumbnail.jpg'), full: require('./livingroom/img_3470.jpg') },
    img_3471: { thumbnail: require('./livingroom/img_3471_thumbnail.jpg'), full: require('./livingroom/img_3471.jpg') },
    img_3472: { thumbnail: require('./livingroom/img_3472_thumbnail.jpg'), full: require('./livingroom/img_3472.jpg') },
    img_3473: { thumbnail: require('./livingroom/img_3473_thumbnail.jpg'), full: require('./livingroom/img_3473.jpg') },
    img_3474: { thumbnail: require('./livingroom/img_3474_thumbnail.jpg'), full: require('./livingroom/img_3474.jpg') },
    img_3475: { thumbnail: require('./livingroom/img_3475_thumbnail.jpg'), full: require('./livingroom/img_3475.jpg') },
    img_3476: { thumbnail: require('./livingroom/img_3476_thumbnail.jpg'), full: require('./livingroom/img_3476.jpg') },
    img_3477: { thumbnail: require('./livingroom/img_3477_thumbnail.jpg'), full: require('./livingroom/img_3477.jpg') },
    img_3482: { thumbnail: require('./livingroom/img_3482_thumbnail.jpg'), full: require('./livingroom/img_3482.jpg') },
    img_3485: { thumbnail: require('./livingroom/img_3485_thumbnail.jpg'), full: require('./livingroom/img_3485.jpg') },
    img_3486: { thumbnail: require('./livingroom/img_3486_thumbnail.jpg'), full: require('./livingroom/img_3486.jpg') },
    img_3487: { thumbnail: require('./livingroom/img_3487_thumbnail.jpg'), full: require('./livingroom/img_3487.jpg') }
  },
  others: {
    img_3444: { thumbnail: require('./others/img_3444_thumbnail.jpg'), full: require('./others/img_3444.jpg') },
    img_3445: { thumbnail: require('./others/img_3445_thumbnail.jpg'), full: require('./others/img_3445.jpg') },
    img_3446: { thumbnail: require('./others/img_3446_thumbnail.jpg'), full: require('./others/img_3446.jpg') }
  },
  outdoor: {
    img_3403: { thumbnail: require('./outdoor/img_3403_thumbnail.jpg'), full: require('./outdoor/img_3403.jpg') },
    img_3404: { thumbnail: require('./outdoor/img_3404_thumbnail.jpg'), full: require('./outdoor/img_3404.jpg') },
    img_3411: { thumbnail: require('./outdoor/img_3411_thumbnail.jpg'), full: require('./outdoor/img_3411.jpg') },
    img_3447: { thumbnail: require('./outdoor/img_3447_thumbnail.jpg'), full: require('./outdoor/img_3447.jpg') },
    img_3448: { thumbnail: require('./outdoor/img_3448_thumbnail.jpg'), full: require('./outdoor/img_3448.jpg') },
    img_3483: { thumbnail: require('./outdoor/img_3483_thumbnail.jpg'), full: require('./outdoor/img_3483.jpg') },
    img_3484: { thumbnail: require('./outdoor/img_3484_thumbnail.jpg'), full: require('./outdoor/img_3484.jpg') }
  }
};

export default Images;
