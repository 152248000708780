import React, { useState } from 'react';
import './gallery.css';
import AmicaRoseNavbar from './AmicaRoseNavbar'; 
import Images from './amicarosepictures/Images'; 

const LazyImage = ({ src, srcSet, alt, onClick }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <img
      src={src}
      srcSet={srcSet}
      alt={alt}
      className={`gallery-image ${loaded ? 'loaded' : 'loading'}`}
      onClick={onClick}
      onLoad={() => setLoaded(true)}
      loading="lazy" 
    />
  );
};

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [category, setCategory] = useState(null);
  const [openCategory, setOpenCategory] = useState(null);

  const renderImages = (category, images) => {
    return Object.keys(images).map((key, index) => (
      <LazyImage
        key={index}
        src={images[key].thumbnail}
        srcSet={`${images[key].thumbnail} 1x, ${images[key].full} 2x`} // Use srcset for responsive images
        alt={`${category}-${index}`}
        onClick={() => {
          setSelectedImage(images[key].full);
          setSelectedIndex(index);
          setCategory(category);
        }}
      />
    ));
  };

  const closeModal = () => {
    setSelectedImage(null);
    setSelectedIndex(null);
    setCategory(null);
  };

  const handlePrev = (e) => {
    e.stopPropagation();
    if (selectedIndex > 0) {
      const newIndex = selectedIndex - 1;
      setSelectedImage(Images[category][Object.keys(Images[category])[newIndex]].full);
      setSelectedIndex(newIndex);
    }
  };

  const handleNext = (e) => {
    e.stopPropagation();
    if (selectedIndex < Object.keys(Images[category]).length - 1) {
      const newIndex = selectedIndex + 1;
      setSelectedImage(Images[category][Object.keys(Images[category])[newIndex]].full);
      setSelectedIndex(newIndex);
    }
  };

  const toggleCategory = (category) => {
    setOpenCategory(openCategory === category ? null : category);
  };

  return (
    <div>
      <div className="amica-rose-container">
        <AmicaRoseNavbar />
      </div>
      <div className="gallery-container">
        <p className="gallery-instructions">Click on the rooms to view images.</p>
        <div className="gallery-category-container">
          <h2 onClick={() => toggleCategory('livingroom')}>
            Living Room <span className={`arrow ${openCategory === 'livingroom' ? 'up' : 'down'}`}></span>
          </h2>
          {openCategory === 'livingroom' && (
            <div className="gallery-category">
              {renderImages('livingroom', Images.livingroom)}
            </div>
          )}
        </div>
        <div className="gallery-category-container">
          <h2 onClick={() => toggleCategory('kitchen')}>
            Kitchen <span className={`arrow ${openCategory === 'kitchen' ? 'up' : 'down'}`}></span>
          </h2>
          {openCategory === 'kitchen' && (
            <div className="gallery-category">
              {renderImages('kitchen', Images.kitchen)}
            </div>
          )}
        </div>
        <div className="gallery-category-container">
          <h2 onClick={() => toggleCategory('bedroom1')}>
            Bedroom 1 <span className={`arrow ${openCategory === 'bedroom1' ? 'up' : 'down'}`}></span>
          </h2>
          {openCategory === 'bedroom1' && (
            <div className="gallery-category">
              {renderImages('bedroom1', Images.bedroom1)}
            </div>
          )}
        </div>
        <div className="gallery-category-container">
          <h2 onClick={() => toggleCategory('bedroom2')}>
            Bedroom 2 <span className={`arrow ${openCategory === 'bedroom2' ? 'up' : 'down'}`}></span>
          </h2>
          {openCategory === 'bedroom2' && (
            <div className="gallery-category">
              {renderImages('bedroom2', Images.bedroom2)}
            </div>
          )}
        </div>
        <div className="gallery-category-container">
          <h2 onClick={() => toggleCategory('bathroom')}>
            Bathroom <span className={`arrow ${openCategory === 'bathroom' ? 'up' : 'down'}`}></span>
          </h2>
          {openCategory === 'bathroom' && (
            <div className="gallery-category">
              {renderImages('bathroom', Images.bathroom)}
            </div>
          )}
        </div>
        <div className="gallery-category-container">
          <h2 onClick={() => toggleCategory('outdoor')}>
            Front/Back Yard <span className={`arrow ${openCategory === 'outdoor' ? 'up' : 'down'}`}></span>
          </h2>
          {openCategory === 'outdoor' && (
            <div className="gallery-category">
              {renderImages('outdoor', Images.outdoor)}
            </div>
          )}
        </div>
        <div className="gallery-category-container">
          <h2 onClick={() => toggleCategory('others')}>
            Others <span className={`arrow ${openCategory === 'others' ? 'up' : 'down'}`}></span>
          </h2>
          {openCategory === 'others' && (
            <div className="gallery-category">
              {renderImages('others', Images.others)}
            </div>
          )}
        </div>
      </div>
      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content-wrapper">
            <span className="close" onClick={closeModal}>&times;</span>
            <img className="modal-content" src={selectedImage} alt="Enlarged" />
            <button className="prev" onClick={handlePrev}>&#10094;</button>
            <button className="next" onClick={handleNext}>&#10095;</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
