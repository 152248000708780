import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '1a7eeab6-ab02-49a2-ae13-8b5e95039664',
  clientToken: 'pub206f2f2ebd1fc87a2e9b110e0cde1f50',
  site: 'datadoghq.com',
  service: 'amica-stays',
  env: 'prod',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 50,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
