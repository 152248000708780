import React from 'react';
import AmicaRoseNavbar from './AmicaRoseNavbar';
import { APIProvider, Map, AdvancedMarker, Pin } from '@vis.gl/react-google-maps';
import './map.css';

const MapComponent = () => {
    const center = { lat: 54.5887565612793, lng: -5.904935359954834 };

    const locations = [
        { key: 'location', location: center },
    ];

    return (
        <Map
            defaultZoom={15}
            defaultCenter={center}
            mapId='855351c2d5093802'
            onCameraChanged={(ev) =>
                console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)
            }
        >
            {locations.map(poi => (
                <AdvancedMarker key={poi.key} position={poi.location}>
                    <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} />
                </AdvancedMarker>
            ))}
        </Map>
    );
};

const MapPage = () => (
    <APIProvider apiKey={'AIzaSyBug_yCZlXBzv22RnaK2v0YYzusomYODao'} onLoad={() => console.log('Maps API has loaded.')}>
        <div>
            <div className="amica-rose-container">
                <AmicaRoseNavbar />
            </div>
            <div className="map-container">
                <h2>Property Address</h2>
                <p>86 Rosebery Road, BT6 8JF Belfast</p>
                
                <h2>Directions</h2>
                <p>By bus, from Belfast City Centre, take Bus Route 6A towards Willowfield. The bus runs every 10 minutes during the day. Get off at stop Willowfield and walk 6 minutes to Rosebery Road. The fare is £2.30 per person. More details available at <a href="https://www.translink.co.uk" target="_blank" rel="noopener noreferrer" className="no-underline-link">Translink</a></p>
                <p>By car, drive towards Lanyon Station and continue onto Albert Bridge. Turn right onto Ravenhill Road, then left into My Lady's Road, and finally right onto Rosebery Road. Amica Rose is on the left at 86 Rosebery Road. The total drive is about 8 minutes (1.8 miles).</p>
                <p>Street parking is available in a quiet neighborhood.</p>

                <h2>Location</h2>
                <div id="map" className="google-map-container">
                    <MapComponent />
                </div>
            </div>
        </div>
    </APIProvider>
);

export default MapPage;
