import React, { useState } from 'react';
import './amicaRose.css';
import image1 from './amicarosepictures/livingroom/img_3470.jpg';
import image2 from './amicarosepictures/livingroom/img_3471.jpg';
import image3 from './amicarosepictures/livingroom/img_3474.jpg';
import image4 from './amicarosepictures/bedroom1/img_3463.jpg';
import image5 from './amicarosepictures/kitchen/img_3459.jpg';
import image6 from './amicarosepictures/bedroom2/img_3456.jpg';
import image7 from './amicarosepictures/bathroom/img_3451.jpg';

const Carousel = ({ closeCarousel }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [image1, image2, image3, image4, image5, image6, image7];

  const nextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleClickOutside = (event) => {
    if (event.target.id === 'carousel') {
      closeCarousel();
    }
  };

  return (
    <div id="carousel" className="amica-rose-carousel" onClick={handleClickOutside}>
      <button className="amica-rose-close-carousel" onClick={closeCarousel}>X</button>
      <div className="amica-rose-carousel-content">
        <img src={images[currentImageIndex]} alt={`Slide ${currentImageIndex}`} />
        <button className="carousel-control prev" onClick={prevSlide}>‹</button>
        <button className="carousel-control next" onClick={nextSlide}>›</button>
      </div>
    </div>
  );
};

export default Carousel;
