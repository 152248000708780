import React from 'react';
import { Link } from 'react-router-dom';

const AmicaGuesthouse = () => (
  <div>
    <h1>Amica Guesthouse</h1>
    <p>Details about Amica Guesthouse...</p>
    <Link to="/">Home</Link>
    <Link to="/amicarose">Amica Rose</Link>
  </div>
);

export default AmicaGuesthouse;
