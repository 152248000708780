import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './amicarosenavbar.css'; // Import CSS file

const AmicaRoseNavbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleBookNowClick = () => {
        window.location.href = 'https://checkout.lodgify.com/amica-rose/en/#/593860'; //https://checkout.lodgify.com/emanuele-tattolo-fda88a/en/#/593860
    };

    return (
        <nav className="amica-rose-navbar">
            <Link to="/amicarose/overview">
                <img src="/amica/amicarose.png" alt="Amica Rose Logo" className="amica-rose-logo" />
            </Link>
            <div className="amica-rose-menu-toggle" onClick={toggleMenu}>
                <svg viewBox="0 0 24 24" className="amica-rose-menu-icon">
                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
                </svg>
            </div>
            <ul className={`amica-rose-nav-links ${isMenuOpen ? 'active' : ''}`}>
                <li><Link to="/amicarose/overview">Overview</Link></li>
                <li><Link to="/amicarose/gallery">Gallery</Link></li>
                <li><Link to="/amicarose/map">Map</Link></li>
                <li><Link to="/amicarose/contacts">Contacts</Link></li>
            </ul>
            <button className="amica-rose-book-now" onClick={handleBookNowClick}>Book Now</button>
        </nav>
    );
};

export default AmicaRoseNavbar;
